@import '~bootstrap/scss/bootstrap';
@import 'variables';
body,
html,
* {
  font-family: 'Montserrat', sans-serif;
}

.pm-wrapper {
  position: relative;
  background: $light_background;
  min-height: 100vh;
}
.pm-logo-container {
  position: absolute;
  top: -80px;
  left: 50%;
  margin-left: -274px;
  right: auto;
  max-width: 548px;
  max-height: 548px;
  min-width: 548px;
  min-height: 548px;
  border-radius: 20px;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }

  .pm-text-container {
    transform: rotate(-45deg);
    text-align: center;
    h2 {
      text-align: center;
      font-weight: bold;
      color: $dark_orange;
    }

    p {
      font-size: 12px;
    }
  }
}

.intro-text {
  padding-top: 400px;
  h3,
  h5 {
    text-align: center;
    font-weight: 600;
  }
  h3 {
    font-weight: 700;
  }

  h5 {
    font-size: 14px;
  }
}

p {
  color: $text_color;
}
.main-user-buttons {
  padding: 24px 0px 0px 0px;
}
.user-button,
.user-button-small {
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: $white;
  padding: 32px;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: 0.2s ease;
  border:thin solid transparent;

  .user-button-text {
    span {
      font-size: 16px;
      color: $dark;
      font-weight: 400;
    }
    color: $dark;
    line-height: 1.2rem;
    font-weight: 700;
    font-size: 16px;
  }
  .icon-left {
    margin: 0px 16px 0px 0px;
  }
  .icon-right,
  .icon-left {
    transition: 0.3s ease;
    width: 24px;
    color: $dark;
  }
  .icon-right {
    width: 32px;
   
  }

  &:hover {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.05);
    border:thin solid $dark_orange;
    .user-button-text {
      color: $dark_orange;
    }
    .icon-right {
      color:$dark_orange;
      transform: translateX(5px);
    }
  }
}

.user-button-small {
  .icon-left.fill-gradient-orange {
    width: 64px;
    height: 64px;
    border-radius: 12px;
    padding: 0px;
    color: $white;
 color:$dark_orange;
 background-color: #fff;
  }
}

.user-button-small-special {
  align-items: center;
  padding: 6px 24px 6px 6px;
  min-height: 64px;
}

/** Login pages */
.login-form-page {
  .main-icon {
    width: 48px;
    margin: 0 auto;
    color: $dark_orange;
    padding: 24px 0px;
  }
  h2 {
    font-weight: 700;
    text-align: center;
    color: $dark_orange;
  }

  h4 {
    text-align: center;
    font-size: 15px;
    font-weight: 600;
  }
}

.inner-page {
  h2 {
    font-weight: 800;
    font-size: 32px;
    color:$dark_orange;
  }

  h4 {
    color: $dark;
    font-weight: 600;
    font-size: 18px;
  }
}

.input-element-small-ok{
  label{
    margin:0px !important;
    margin-right:12px !important;
  }
  svg{
    width:40px;
    color:$dark_orange;
    margin-right:12px !important;
  }

  div.check-circle{
    margin-right:12px !important;
cursor: pointer;
    width:32px;
    height:32px;
    border:thin solid #000;
    border-radius: 44px;
  }

}
.input-element,
.input-element-small {
  position: relative;
  margin-bottom: 24px;

  select {
    width: 100%;
    height: 56px;
    border-radius: 8px;
    padding-left: 12px;
    border: thin solid #d8d8d8;
    background: #f1f1f1;
    cursor: pointer;
  }
  label {
    cursor: pointer;
    font-size: 14px;
    color: $dark;
    font-weight: 600;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;

    span {
      small {
        font-size: 12px;
        color: #666;
      }
    }
  }

  .input-wrapper {
    position: relative;
    display: flex;
    .left-icon {
      position: absolute;
      top: 20px;
      width: 18px;
      left: 16px;
      color: #999;
    }

    .right-icon{
      width: 16px;
      position: absolute;
      right: 16px;
      top: 18px;
      cursor: pointer;

      &:hover{
        svg{
          fill: #000;
        }
      }

      svg{
        fill: #999;
      }

  
    }
    .right-text {
      position: absolute;
      right: 16px;
      top: 20px;
      a {
        color: $dark_orange;
        font-size: 14px;
        text-decoration: none;
        font-weight: 600;

        &:hover {
          color: $dark_orange;
        }
      }
    }
    input.pac-target-input,
    textarea,
    input[type='text'],
    input[type='email'],
    input[type='password'] {
      padding-left: 48px;
      border-radius: 8px;
      border: thin solid #ececec;
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.05);
      height: 64px;
      min-width: 320px;
      width: 100%;
      outline: 0;
      display: block;
      transition: 0.2 ease;
      font-weight: 600;

      &:focus {
        border: thin solid transparent;
        box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.15);
        font-weight: 600;
      }
    }
    textarea{
      padding-top:24px;
      height:200px;
    }

    input[type='password'] {
      font-size: 16px;
    }
  }


  .input-normal {
    input.pac-target-input,
    input[type='text'],
    input[type='email'],
    input[type='number'],
    input[type='password'] {
      border-radius: 4px;
      border: thin solid #fff;
      height: 56px;
      min-width: 320px;
      width: 100%;
      outline: 0;
      display: block;
      transition: 0.2 ease;
      font-weight: 500;
      padding-left: 12px;
      font-size: 16px;
      background: #f1f1f1;

      &:focus,
      &:active {
        border: thin solid $light_background;
        background: #f1f1f1;
        font-weight: 600;
      }
    }

    input[type='password'] {
      font-size: 24px;
    }
  }

  .input-element-ok {
    margin: 0px 0px 0px 24px;
    cursor: pointer;
    label {
      font-weight: 700;
      font-size: 16px;
      margin: 0px 0px 0px 6px;
    }

    .check-circle {
      width: 22px;
      height: 22px;
      border-radius: 4px;
      background-color: #d8d8d8;
    }

    svg {
      width: 32px;
      color: $dark_orange;
    }
  }

  .primary-button {
    border-radius: 4px;
    display: flex;
    align-items: center;
    border: none;
    background: $dark_orange;
    width: 100%;

    background: linear-gradient(90deg, $dark_orange 0%, $orange 100%);
    height: 56px;
    min-width: 160px;
    padding: 0px 24px;
    justify-content: center;
    margin: 0 auto;
    .button-text {
      transition: 0.2s ease;
      color: $white;
      font-weight: 600;
      font-size: 16px;
    }
    .right-icon {
      margin: 0px 6px;
      width: 24px;
      color: $white;
      transition: 0.2s ease;
    }

    &:hover {
      background: $dark_orange;
      .button-text {
        transform: translateX(-6px);
      }
      .right-icon {
        transform: translateX(6px);
      }
    }
  }

  .primary-button-small {
    border-radius: 8px;
    display: flex;
    align-items: center;
    border: none;
    background: $dark_orange;
    background: $dark_orange;
    background: linear-gradient(
      90deg,
      $dark_orange 0%,
      $orange 100%
    );
    height: 48px;
    min-width: 120px;
    padding: 0px 24px;
    justify-content: center;
    margin: 0 auto;
    .button-text {
      transition: 0.2s ease;
      color: $white;
      font-weight: 600;
      font-size: 16px;
    }
    .right-icon {
      margin: 0px 6px;
      width: 24px;
      color: $white;
      transition: 0.2s ease;
    }

    &:hover {
      background: $dark_orange;
    }
  }

  .primary-button-xsmall {
    border-radius: 8px;
    display: flex;
    align-items: center;
    border: none;
    background: $dark_orange;
    background: linear-gradient(
      90deg,
      $dark_orange 0%,
      $orange 100%
    );
    height: 36px;
    min-width: 100px;
    padding: 0px 12px;
    justify-content: center;
    margin: 0 auto;
    .button-text {
      transition: 0.2s ease;
      color: $white;
      font-weight: 600;
      font-size: 14px;
    }
    .right-icon {
      margin: 0px 6px;
      width: 24px;
      color: $white;
      transition: 0.2s ease;
    }

    &:hover {
      background: $dark_orange;
    }
  }

  .generate-button {
    background: $white !important;

    div.button-text {
      color: #000 !important;
    }

    border: thin solid $dark_orange;

    &:hover {
      background: #f1f1f1 !important;
    }
  }
  .no-animation {
    &:hover {
      background: $dark_orange;
      .button-text {
        transform: translateX(0px);
      }
      .right-icon {
        transform: translateX(0px);
      }
    }
  }

  .button-add {
    min-width: 48px;
    height: 48px;
    width: 48px;
    padding: 0px;
    .right-icon {
      width: 32px;
    }
  }

  &.error {
    input[type='text'],
    input[type='email'],
    input[type='password'],
    input[type='tel'],
    input[type='number'] {
      border: 2px solid rgb(211, 53, 53);

      &:focus {
        border: 2px solid rgb(211, 53, 53);
      }
    }
  }
}

.input-element-small {
  margin-bottom:12px;



}

.spacer-sm {
  padding: 6px 0px;
}

.spacer-md {
  padding: 16px 0px;
}

.spacer-lg {
  padding: 24px 0px;
}

.text-orange {
  color: $dark_orange;
}

.sub-title-with-icon {
  .icon-left {
    width: 36px;
  
    color: $orange;
    margin: 0px 12px 0px 0px;

    img{
      width:100%;
    }
  }

  h3 {
    font-weight: 800;
    color: $dark;
    font-size: 20px;
    small{
      display:block;
      font-size:12px;
      color:#666;
      
    }
  }
}

.acc-wrapper {
  border: thin solid transparent;
  border-radius: 12px;
  border: thin solid $dark_orange2;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow:0px 1px 5px rgba(0, 0, 0, 0.05);
  transition: 0.2s ease;

  &:hover {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
  }
  &.open {
    border: thin solid $dark_orange;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
    display:block !important;
    .acc-panel {
      display: block !important;
    }
  }
  .acc-wrapper-button {
    border: none;
    background: none;
    outline: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span.text-orange{
      font-size: 16px;
    text-align: left;
    font-weight: 600;
    text-align: left;
    }

    span.status-text-badge{
font-size: 12px;
font-weight: 600;
color:#888;
text-align: left;
> span{
  font-size: 12px;
  text-align: left;
}
    }
    span.status-text-badge-error{
     > span{
      color: rgb(206, 56, 56);
     }
    }
    span.status-text-badge-success{
      > span{
       color: rgb(85, 197, 85);;
      }
     }
     span.status-text-badge-warning{
      > span{
       color:rgb(248, 157, 52);
      }
     }

    span.status-badge{
   //   width:200px;
   display: block;
      text-align: left;
      font-weight: 600;
      display: flex;
      align-items: center;
      align-items: center;
    padding: 4px 10px;
    border-radius: 24px;
    color:#fff;
    font-size: 12px;
    }
    span.status-badge-success{
      background: rgb(85, 197, 85);
    }

    span.status-badge-info{
      background: rgb(180, 211, 236);
    }
    span.status-badge-error{
      background: red;
    }

    span.status-badge-warning{
      background: orange;
    }
    > div {
      width: 100%;
      text-align: left;
      .text-orange {
        width: 90%;
      }
    }

    .icon-check-circle-select {
      color: $dark_orange;
      margin-right: 12px;
    }

    .icon-check-circle {
      border-radius: 100px;
      width: 28px;
      height: 28px;
      border: 2px solid $orange;
      cursor: pointer;
      margin-right: 12px;
    }
   

    svg {
      width: 28px;
    }

    .rotate-180 {
      transform: rotate(-180deg);
    }
  }

  .acc-panel {
    border-top: thin dashed $orange;
    margin-top: 12px;
    padding-top: 12px;

    display: none;

    p {
      font-size: 12px;
      font-weight: 600;
      text-align: left;
      margin-bottom: 16px;
  
      span.text-orange {
        font-weight: 600;
      }
    }
  }

  &.open {
    border: thin solid $dark_orange;
  }
}

.left-round-button {
  border-radius: 50px;
  background: $white;
  width: 52px;
  height: 52px;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  position: relative;
  z-index: 300;
  cursor: pointer;
  transition: 0.2s ease;
  color: $dark_orange;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  padding: 12px;

  &:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
  }
}

input[type="radio"]{
  width: 24px;
  height: 24px;
}

.ticket-item {
  border-radius: 8px;
  color: $white;
  display: flex;
  align-items: center;
  background: $info_blue;
  padding: 6px 12px;
  position: absolute;
  right: 0px;
  top: 5px;
  p {
    margin: 0px;
    padding: 0px;
    color: $white;
    font-weight: 600;
  }

  div {
    width: 24px;
    color: $white;
    cursor: pointer;
  }
}

.steps {
  img {
    margin: 24px auto 0px auto;
    display: block;
    width: 100%;
  }
}

.no-services {
  padding: 24px;
  border: thin solid orange;
  border-radius: 8px;
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.15);

  .icon-top {
    width: 48px;
    height: 48px;
    color: orange;
    margin: 0 auto;
  }

  p {
    font-weight: 600;
  }
}

.search-bar {
  position: relative;
  margin-bottom: 24px;
  .right-icon {
    width: 24px;
    color: $orange;
    position: absolute;
    right: 24px;
    top: 12px;
  }

  input[type='text'] {
    padding-left: 24px;
    border-radius: 8px;
    border: thin solid #ececec;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.05);
    height: 48px;
    min-width: 320px;
    width: 100%;
    outline: 0;
    display: block;
    transition: 0.2 ease;
    font-weight: 500;
    background: #f1f1f1;

    &:focus {
      border: thin solid transparent;
      box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.15);
      font-weight: 600;
    }
  }
}

.modal-small,
.filter-modal {
  .top-icon {
    width: 44px;
    color: $dark_orange;
    margin: 0 auto;
  }

  .modal-dialog {
    width: 280px;
  }
  p {
    text-align: center;
    font-weight: 500;
    font-size: 14px;
  }
  h4 {
    font-weight: 600;
    text-align: center;
    font-size: 18px;
  }
}

.accordian-list {
  h3 {
    font-weight: 600;
  }
}

.btn-edit,
.btn-delete {
  padding: 12px 24px;
  margin: 0 auto;
  color: $dark;
  background: $white;
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.15);
  margin: 0px 12px;
  border: thin solid transparent;
  &:hover {
    background: $white;
    color: $dark;
  }
  span {
    svg {
      width: 16px;
      margin-right: 6px;
    }
  }
}

.btn-edit {
  border: thin solid $orange;

  &:hover {
    background: $orange;
    border: thin solid transparent;
    color: $white;
  }
}

.btn-delete {
  border: thin solid $danger;

  &:hover {
    background: $danger;
    border: thin solid transparent;
    color: $white;
  }
}

.edit-modal {

  .modal-header{
    display:flex;
    position: relative;
    justify-content: center;
    border:none;
    .modal-title{
      text-align: center;
      justify-content: center;
    }
    .btn-close{
      position: absolute;
      right:0px;
    }
  }
  .top-icon {
    width: 24px;
    margin: 0 12px 0px 0px;
    color: $orange;
  }

  h4 {
    font-weight: 600;
    font-size: 16px;
  }
  h4,
  p {
    text-align: center;
  }

  .input-wrapper {
    position: relative;
    input[type='text'],
    input[type='email'],
    input[type='number'] {
      text-align: left;
      font-weight: 400;
      font-size: 16px;
      padding-left: 24px;
    }

 
  }

  .modal-content {
    padding: 24px;
  }
}
.confirm-modal {
  .top-icon {
    width: 32px;
    margin: 0 auto;
    color: $orange;
  }
  h4 {
    font-weight: 600;
  }
  h4,
  p {
    text-align: center;
  }
  input {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    padding-left: 0px;
  }

  .modal-content {
    padding: 24px;
  }
}

.icon-sign-out {
  width: 36px;
  color: $dark_orange;
  cursor: pointer;

  &:hover {
    color: $dark;
  }
}

.forgot-password-link-wrapper {
  a {
    color: $dark_orange;
    font-weight: 600;
    font-size: 14px;

    &:hover {
      color: $dark;
    }
  }
}

.service-item {
  label {
    color: $dark;
    margin: 0px;
    cursor: pointer;
    font-size:14px;
    font-weight: 600;
  }

  .badge {
    cursor: pointer;
    padding: 12px;
    margin-right: 12px;
    display: flex;
    align-items: center;

    &:hover {
      background: #f3f3f3 !important;
    }

    input {
      margin-right: 12px;
      width:20px;
      height:20px;
    }
  }
}

.text-username {
  font-size: 16px;
}

.admin-type {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
  color: $dark_orange;
}

.empty-list-data {
  h4 {
    font-size: 16px;
    color: #2a2b2e;
  }
}

.username-password {
  background: #faf9f9;
  padding: 24px;
  border-radius: 12px;
}

.password-toggle {
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  top: 46px;
  right: 12px;
  color: #666;

  &:hover {
    color: #2a2b2e;
  }
}

.pac-container {
  z-index: 3000 !important;
}

.filter-modal {
  .modal-body {
    padding: 0px;
  }
  .modal-dialog {
    width: auto;
    padding: 0px;
  }

  .modal-content {
    width: 100%;
  //  max-width: 600px;
    padding: 24px;

    .modal-header{
      border:none;
    }
  }

  select {
    background: #f1f1f1;
    border: none;
    outline: 0;
    margin-top: 12px;
    //-webkit-appearance: none;
  }

  .datepicker-wrapper {
    color: $dark_orange;
    display: flex;
    padding: 12px;
    border-radius: 12px;
    background: #f6f6f6;
    width: 49%;

    .picker-icon {
      width: 48px;
      margin-right: 12px;
    }
    .date-wrapper {
      width: 66%;

      //       .react-datepicker{
      //             position: absolute;
      // z-index: 300;
      // left: 36px;
      // border: none;
      // box-shadow: 0px 0px 25px rgba(0,0,0,.2);
      //       }
      input {
        border: none;
        background: none;
        font-weight: 600;
        color: $dark_orange;
        font-size: 14px;
        cursor: pointer;
        outline: 0;
        width: 100%;
        &:hover {
          color: #000;
          &:hover {
            background: rgba(255, 106, 0, 0.106);
          }
        }
      }
      small {
        color: #000;
        font-weight: 600;
        font-size: 12px;
      }
    }
  }

  h2 {
    font-weight: 700;
    font-size: 32px;
    color: #000;
  }

  .filter-date-wrapper {
  }
}

.download-button {
  button {
    border: none;
    width: 48px;
    height: 36px;
    outline: 0;
    padding: 0px 12px;
    background: #d8d8d8;
    display: block;
    border-radius: 12px;

    &:hover {
      background: #f1f1f1;
      color: $dark_orange;
    }
  }
}

.support-services-info {
  font-weight: 600;
  margin-bottom: 12px;
  color: #999;
  font-size: 10px;
  text-align: center;
  p {
    margin: 0px;
    text-align: center;
    color: #000;
  }
}

.Toastify__toast-container {
  padding: 0px;
  max-width: 375px;
  width: 100%;
  min-width: 320px;
}
.Toastify__toast {
  padding: 12px;
  border-radius: 12px;
}

.Toastify__toast-body {
  font-weight: 600;
  font-size: 14px;
}

.status-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display:none;
 // margin-left: 5px;
}

.status-green {
  background-color: rgb(85, 197, 85);
}

.status-red {
  background-color: $danger;
}

.status-yellow {
  background-color: orange;
}

.privacy-terms-link-wrapper {

  input[type='checkbox'] {
    margin-right: 12px;
  }
  label {
    display: flex;
    justify-content: center;
  }
  p{
    margin:0px;
    font-size: 14px;
    font-weight: 600;
  }

  a {
    color: $dark_orange;
    font-weight: 600;
    font-size: 14px;
    text-decoration: underline;

    &:hover {
      color: $dark_orange;
      text-decoration: none;
    }
  }
}

.footer-layout {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  text-align: center;
  background: $light_background;
  p{
    margin:0px;


    a{
      display:inline-block;
      font-weight: bold;
      color:$dark_orange;
    }
  }
}

.App {
  padding-bottom: 64px;
}
.modal-backdrop.show{
  opacity:.65;
}
.verification-modal{

  .modal-header{
    justify-content: center;
    border:none;
   
  }


  .modal-content{
    padding:15px;
  }
  .modal-body{
    .input-element{
      .input-wrapper{
        position: relative;
       

        input{
          width:100%;
          min-width:100%;
          padding:0px;
          text-align: center;
          font-size:16px;
          font-weight: 600;
          color:$dark;
          background-color: #f1f1f1;
          border:none;
          box-shadow: none;
          
        }
      }
    }
    p{
      font-size:14px;
      font-weight:700;
      text-align: center;
    }
  }
  .modal-title{
    font-size:18px;
    font-weight: 700;
    color:$dark;
    text-align: center;
  }
  .modal-dialog{
    width:280px;
  height:280px;
  }
  .modal-footer{
    justify-content: center;
    border:none;

    .input-element{
      padding:0px;
      margin:0px;
    }
    button{
      width:100%;
      min-width:100%;
      height:48px;

    }
  }
}

.modal{
  .modal-dialog{
  //  height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.gender-list{
  span.badge{
    background-color: #f1f1f1 !important;
    color: #212529;
    font-size: 12px;
    padding: 6px 12px;
    border-radius: 4px;
    margin-right: 12px;
  }
}
.gender-items{
  display:flex;
  .gender-item{

    span.badge{
      background-color: #f1f1f1 !important;
      display:flex;
      align-items: center;
      padding:12px 12px 12px 12px;
      margin-right:12px;
    }

    label{
      color:$dark;
      margin:0 !important;
      font-weight: 600;
      cursor: pointer;
    }

    input{
      border:none;
      margin-right:12px;
      width:20px;
      height:20px;
    }

  }
}