$dark_orange:#338BB1;
$dark_orange2:#cfe4ee;
$orange:#2FB27E;
$orange_light_1:#FEFCF1;
$orange_light_2:#FBF4E4;
$black:#2A2B2E;
$light_background:#F5FFFF;
$white:#ffffff;
$text_color:#2a2b2e;
$info_blue:#2868f2;
$danger:red;